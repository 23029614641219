type FilterHeaderProps = {
  openFilter: string;
  filterId: string;
  toggleVisibility: (filterId: string) => void;
  filterName: string;
};

const FilterHeader = ({
  openFilter,
  filterName,
  filterId,
  toggleVisibility,
}: FilterHeaderProps) => {
  const filterOpen = openFilter === filterId;

  return (
    <div
      className={
        "pt-4 flex-1 text-center uppercase border-l-2 border-r border-white cursor-pointer select-none" +
        (filterOpen ? " text-blue bg-white" : " text-white")
      }
      onClick={() => toggleVisibility(filterId)}
    >
      <div className="mb-4">{filterName}</div>
      <div className="font-bold">{filterOpen ? " \uFE3F " : "\uFE40"}</div>
    </div>
  );
};

export default FilterHeader;
