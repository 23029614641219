import { FC, useState } from "react";
import { useSurveyContext } from "../../../context/SurveyContext";
import TextInputWithLabel from "../../TextInputWithLabel";
import { isEmail } from "utils/validationUtils";

const ERROR_MESSAGE_IS_REQUIRED = "This field is required, please fill it out";
const ERROR_MESSAGE_INVALID_EMAIL = "Please enter a valid email address";
const ERROR_MESSAGE_EMAIL_MISMATCH = "Emails do not match";

type Props = {
  question: any;
  activePart: any;
  validate: (question: any, answer: string, secondaryAnswer?: string) => void;
};

const EmailConfirmQuestion: FC<Props> = ({
  activePart,
  question,
  validate,
}) => {
  const {
    survey: { answers },
    setAnswer,
  } = useSurveyContext();

  const [emailState, setEmailState] = useState({
    email: answers.email || "",
    confirmEmail: answers.email || "",
  });

  const [errors, setErrors] = useState<{
    email: string;
    confirmEmail: string;
  }>({
    email: "",
    confirmEmail: "",
  });

  const isShown = question.part === activePart;

  const validateFields = (
    email: string,
    confirmEmail: string,
    field: "email" | "confirmEmail"
  ) => {
    let newErrors: { email: string; confirmEmail: string } = {
      email: "",
      confirmEmail: "",
    };

    // Validate the current field only
    if (field === "email") {
      if (email === "") {
        newErrors.email = ERROR_MESSAGE_IS_REQUIRED;
      } else if (!isEmail(email)) {
        newErrors.email = ERROR_MESSAGE_INVALID_EMAIL;
      } else if (confirmEmail && email !== confirmEmail) {
        newErrors.email = ERROR_MESSAGE_EMAIL_MISMATCH;
      }
    }

    if (field === "confirmEmail") {
      if (confirmEmail === "") {
        newErrors.confirmEmail = ERROR_MESSAGE_IS_REQUIRED;
      } else if (!isEmail(confirmEmail)) {
        newErrors.confirmEmail = ERROR_MESSAGE_INVALID_EMAIL;
      } else if (email && email !== confirmEmail) {
        newErrors.confirmEmail = ERROR_MESSAGE_EMAIL_MISMATCH;
      }
    }

    setErrors(newErrors);
    validate(question, email, confirmEmail);
  };

  const handleOnChange = (field: "email" | "confirmEmail", value: string) => {
    const updatedState = { ...emailState, [field]: value };
    setEmailState(updatedState);

    // Validate immediately if the input is cleared to show the required field error
    if (value === "") {
      validateFields(updatedState.email, updatedState.confirmEmail, field);
    } else {
      // Clear the error message for the field being typed in
      setErrors({ ...errors, [field]: "" });
    }

    setAnswer(field, value);
  };

  const handleOnBlur = (field: "email" | "confirmEmail") => {
    validateFields(emailState.email, emailState.confirmEmail, field);
  };

  return (
    <div className={isShown ? "" : "hidden"}>
      <TextInputWithLabel
        label="Email"
        value={emailState.email}
        _onChange={(value) => handleOnChange("email", value)}
        onBlur={() => handleOnBlur("email")}
        placeholder="Enter your email"
        labelClassNames="input-label"
      />
      {errors.email && <div className="mt-2 text-red">{errors.email}</div>}

      <div className="mt-6">
        <TextInputWithLabel
          label="Confirm Email"
          value={emailState.confirmEmail}
          _onChange={(value) => handleOnChange("confirmEmail", value)}
          onBlur={() => handleOnBlur("confirmEmail")}
          placeholder="Confirm your email"
          labelClassNames="input-label"
        />
        {errors.confirmEmail && (
          <div className="mt-2 text-red">{errors.confirmEmail}</div>
        )}
      </div>
    </div>
  );
};

export default EmailConfirmQuestion;
