import { useCallback, useEffect } from "react";
import ReactGA from "react-ga4";
import "babel-polyfill";
import browserHistory from "utils/history";
import CartErrorModal from "./components/modals/CartErrorModal/CartErrorModal";

import Header from "./components/Header";
import Footer from "./components/organisms/Footer";

import TravelRequirementsHeader from "./components/TravelRequirementsHeader";
import { saveState } from "./localStorage";
import useAppSelector from "hooks/useAppSelector";
import IdleTimer from "react-idle-timer";
import { getMinutesInMiliseconds } from "utils/getMinutesInMiliseconds";
import { isDevelopmentMode, isTestMode } from "config/configUtils";
import Routes from "Routes";
import useAppDispatch from "hooks/useAppDispatch";
import { clearCruises } from "actions/cruisesActions";
import CartExpirationModal from "components/modals/CartExpirationModal/CartExpirationModal";
import { useGetFeatureFlags } from "hooks/common/featureFlags/useGetFeatureFlags";
import { AppConfigAction } from "actions/appConfigActions";

const TIMEOUT_IN_MINUTES = 30;

const App = () => {
  const { getFeatureFlagsData } = useGetFeatureFlags();

  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  let idleTimer = null;
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state);
  const isRiverUrl = state.appConfig.isRiverUrl;

  const agencyLoggedIn = state.agency.isAuthenticated;
  const userLoggedIn = state.authentication.isAuthenticated;

  const handleAcceptCookie = useCallback(() => {
    // GA will initialize only for porduction and ocean cruises
    if (!isDevelopmentMode() && !isTestMode() && !isRiverUrl) {
      ReactGA.initialize([
        {
          trackingId: "G-Q1C30XBNVF",
        },
      ]);
    }

    // onetime save the current state (in case user gave consent mid session)
    saveState(state);
  }, [isRiverUrl, state]);

  useEffect(() => {
    // TODO - temporary change only - revert back once texts and wishes are known
    // const consentGiven = getCookieConsentValue();
    const consentGiven = "true";

    if (consentGiven === "true") {
      handleAcceptCookie();
    }

    document.title = isRiverUrl
      ? "TUI River Cruises"
      : "Marella Cruise Control";
  }, [handleAcceptCookie, isRiverUrl]);

  useEffect(() => {
    if (!agencyLoggedIn && !userLoggedIn) {
      dispatch({ type: "CLEAR_PAYMENT" });
      dispatch({ type: "CLEAR_CART" });
      dispatch({ type: "CLEAR_BOOKINGS" });
      dispatch({ type: "CLEAR_ITINERARY" });
      dispatch({ type: "CLEAR_APP_CONFIG" });
      clearCruises();
      localStorage.clear();
    }
  }, [agencyLoggedIn, dispatch, userLoggedIn]);

  useEffect(() => {
    if (getFeatureFlagsData) {
      dispatch<AppConfigAction>({
        type: "SET_FEATURE_FLAGS",
        featureFlags: getFeatureFlagsData,
      });
    }
  }, [dispatch, getFeatureFlagsData]);

  const _onIdle = () => {
    if (agencyLoggedIn || userLoggedIn) {
      browserHistory.push("/logoutUser");
    }
  };

  return (
    <>
      <IdleTimer
        ref={(ref: IdleTimer) => {
          // TODO Improve with new implementation of react-idle-timer
          idleTimer = ref;
        }}
        element={document}
        onIdle={_onIdle}
        timeout={getMinutesInMiliseconds({ minutes: TIMEOUT_IN_MINUTES })}
      />
      <div className="TuiTypeLite">
        <CartExpirationModal />
        <TravelRequirementsHeader />
        <Header />

        {/*main content*/}
        <div className="main-content">
          <Routes />
        </div>

        <Footer />
        <CartErrorModal />
      </div>
    </>
  );
};

export default App;
