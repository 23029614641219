import { FC, ReactNode, SetStateAction, useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { LocationDescriptor } from "history";
import useAppSelector from "hooks/useAppSelector";

import SvgIcon from "components/atoms/SvgIcon";
import Modal from "components/modals/Modal/Modal";
import ModalFooter from "components/modals/ModalFooter";
import Price from "components/Price/Price";
import ContentHeaderImage from "components/ContentHeaderImage";
import TextInputWithLabel from "components/TextInputWithLabel";
import { usePostRefundMxpDining } from "../../hooks/usePostRefundMxpDining";
import { usePostRefundAgencyMxpDining } from "../../hooks/usePostRefundAgencyMxpDining";
import ErrorModal from "components/modals/ErrorModal";

type Props = {
  title: string;
  image: string | null;
  linkTo: string | LocationDescriptor;
  children: ReactNode;
  tooltipText: string;
  tooltipId: string | string[];
  totalPrice: number;
  isBookedByAgency: boolean;
  transactionId: string;
  vacancyId: string;
  availableTime: string;
  nrOfPeople: number;
};
const CartElementPaidActivityMxp: FC<Props> = ({
  title,
  image,
  linkTo,
  tooltipText,
  tooltipId,
  totalPrice,
  children,
  isBookedByAgency,
  transactionId,
  vacancyId,
  availableTime,
  nrOfPeople,
}) => {
  const [showRefundModal, setShowRefundModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const [refundReference, setRefundReference] = useState("");

  const {
    postRefundMxpDining,
    postResetRefundMxpDining,
    postRefundMxpDiningLoading,
    postRefundMxpDiningError,
  } = usePostRefundMxpDining();

  const {
    postRefundAgencyMxpDining,
    postResetRefundAgencyMxpDining,
    postRefundAgencyMxpDiningIsLoading,
    postRefundAgencyMxpDiningError,
  } = usePostRefundAgencyMxpDining();

  const agencySessionType = useAppSelector(
    (state) => state?.agency?.sessionType
  );

  const isLoggedAsAgency = agencySessionType === "SingleSignOn";

  const processRefund = async () => {
    setShowRefundModal(false);
    setShowConfirmationModal(false);

    await postRefundMxpDining({ transactionId, vacancyId });
  };

  const processRefundAgency = async () => {
    setRefundReference("");
    setShowRefundModal(false);

    await postRefundAgencyMxpDining({
      transactionId: refundReference,
      itemId: vacancyId,
    });
  };

  const handleCloseErrorModal = () => {
    if (isLoggedAsAgency) {
      postResetRefundAgencyMxpDining();
    } else {
      postResetRefundMxpDining();
    }
  };

  const isRefundDisabled = () =>
    (isBookedByAgency && !isLoggedAsAgency) ||
    (!isBookedByAgency && isLoggedAsAgency) ||
    postRefundAgencyMxpDiningIsLoading ||
    postRefundMxpDiningLoading;

  return (
    <div>
      <div className="flex flex-wrap bg-blue-lightest shadow sm:p-4 my-4">
        {/* image */}
        <div className="w-full sm:w-1/2 md:w-2/5 xl:w-1/3">
          <ContentHeaderImage
            altText={title + " - header"}
            resourceId={image ?? undefined}
            width={400}
          ></ContentHeaderImage>
        </div>

        {/* rest of card */}
        <div className="sm:flex sm:flex-col px-4 py-2 sm:p-0 w-full sm:w-1/2 md:w-3/5 xl:w-2/3 sm:pl-4 mt-2 sm:mt-0 relative">
          {/* Cancel the booking - show refund modal */}
          <span
            className="text-blue cursor-pointer absolute top-0 right-0 mr-4 mt-2 sm:m-0"
            onClick={() => {
              setShowRefundModal(true);
            }}
          >
            <span className="hidden md:inline">Cancel</span>{" "}
            <SvgIcon name="x" className="w-3 h-3 inline ml-1" />
          </span>

          {/* title link*/}
          <div className="mr-6 md:mr-16">
            {linkTo ? (
              <Link
                className="text-blue underline text-2xl uppercase sm:pr-3 md:pr-0"
                to={linkTo}
              >
                {title}
              </Link>
            ) : (
              <div className="text-blue underline text-2xl uppercase sm:pr-3 md:pr-0">
                {title}
              </div>
            )}
          </div>

          {/* details */}
          <div className="sm:flex-grow">
            <div className="my-2">{children}</div>
          </div>
          {/* cost info */}
          <div className="text-right">
            Already purchased -
            <span
              className="cursor-pointer ml-2 text-blue border-dotted border-b border-blue"
              data-tip={tooltipText}
              data-for={tooltipId}
              data-effect="solid"
              data-place="bottom"
              data-multiline={true}
            >
              See cost
            </span>
          </div>

          <ReactTooltip id={tooltipId as string} />
        </div>
      </div>

      {/* Refund modal */}
      <Modal
        show={showRefundModal}
        onClose={() => {
          setShowRefundModal(false);
        }}
        title="Cancel booking"
      >
        <div className="flex flex-grow flex-col">
          <div className="flex-grow bg-white py-4 px-2 sm:px-4">
            <div>
              <div className="text-lg font-bold">
                Issue refund for the following booking
              </div>

              <div className="bg-blue-lightest p-4 mt-4">
                <div className="text-xl">{title}</div>
                <div className="mt-1">
                  Booking for {nrOfPeople}{" "}
                  {nrOfPeople > 1 ? "people" : "person"} at {availableTime}
                </div>
              </div>

              <div className="mt-2">
                <Price preText="Refund value" price={totalPrice.toString()} />
              </div>
              {isLoggedAsAgency && (
                <div className="my-4">
                  <TextInputWithLabel
                    label="Refund Reference"
                    type="text"
                    value={refundReference}
                    _onChange={(value: SetStateAction<string>) =>
                      setRefundReference(value)
                    }
                    placeholder=""
                    errorMessage=""
                  />
                </div>
              )}
            </div>
          </div>
          <ModalFooter
            onCancel={() => {
              setShowRefundModal(false);
            }}
            actionDisabled={isRefundDisabled()}
            actionButtonText={
              postRefundAgencyMxpDiningIsLoading
                ? "Please wait..."
                : "Refund booking"
            }
            onActionClick={() =>
              isLoggedAsAgency
                ? processRefundAgency()
                : setShowConfirmationModal(true)
            }
          />
        </div>
      </Modal>

      {/* Confirmation modal */}
      <Modal
        show={showConfirmationModal}
        onClose={() => {
          setShowConfirmationModal(false);
        }}
        title="Confirmation"
      >
        <div className="flex flex-grow flex-col">
          <div className="flex-grow bg-white py-4 px-2 sm:px-4">
            <div className="text-xl font-bold">
              Are you sure you want to cancel this booking?
            </div>
          </div>

          <ModalFooter
            cancelLabel="No"
            onCancel={() => {
              setShowConfirmationModal(false);
            }}
            actionDisabled={isRefundDisabled()}
            actionButtonText={
              postRefundMxpDiningLoading ? "Please wait..." : "Yes - cancel"
            }
            onActionClick={processRefund}
          />
        </div>
      </Modal>

      <ErrorModal
        show={Boolean(
          postRefundAgencyMxpDiningError || postRefundAgencyMxpDiningError
        )}
        onClose={() => handleCloseErrorModal()}
        title="Error"
        message={
          postRefundMxpDiningError?.message ??
          "There was an error processing your refund."
        }
      />
    </div>
  );
};

export default CartElementPaidActivityMxp;
