import CartElementCheckout from "components/cartElements/CartElementCheckout";
import { FC } from "react";
import { convertYMDtoDMY, formatCartElementDate } from "utils/dateTimeUtils";

type Props = {
  title: string;
  scheduledDay: string;
  scheduledTime: string;
  price: number;
  dayNr: number;
  nrGuests: number;
  restaurantImageUrl: string | null;
};
const CartElementMxpCheckout: FC<Props> = ({
  title,
  price,
  dayNr,
  scheduledTime,
  scheduledDay,
  nrGuests,
  restaurantImageUrl,
}) => {
  return (
    <CartElementCheckout
      name={title}
      imageReference={restaurantImageUrl ?? ""}
      linkTo={`/mxp/restaurants/${scheduledDay}`}
      pricePreText="Restaurant cost:"
      price={price.toString()}
    >
      <div>
        On day {dayNr} ({formatCartElementDate(convertYMDtoDMY(scheduledDay))})
      </div>
      <div className="mt-2">
        Booking for {nrGuests} {nrGuests > 1 ? "people" : "person"} at{" "}
        <span className="font-bold">{scheduledTime}</span>
      </div>
    </CartElementCheckout>
  );
};

export default CartElementMxpCheckout;
