import axios from "axios";
import { getOswUrl, getShipCodeByName } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { IOneSpaWorldListData } from "interfaces/OSW/IOneSpaWorldList";
import { useCallback, useEffect, useState } from "react";
import { getCruisesInfo } from "utils/cruiseUtils";

type Props = {
  date: string;
  sort: string;
};

export const useGetSpaTreatmentsList = ({ date, sort = "asc" }: Props) => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const cruiseDetails = useAppSelector((state) => state.cruises.cruiseDetails);
  const cruisesInfo = getCruisesInfo(cruiseDetails);

  const [data, setData] = useState<IOneSpaWorldListData>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const getSpaTreatmentsList = useCallback(
    async ({ date, sort }: Props) => {
      setLoading(true);
      const URL = `${getOswUrl()}/api/ships/${getShipCodeByName({
        shipName: cruisesInfo.shipName,
      })}/spa-treatment/by-date/${date}?page=1&size=20&sort=price,${sort}`;

      const response = await axios
        .get(URL, {
          headers: {
            SessionId: sessionId,
          },
        })
        .catch((error) => {
          setError(error);
        });

      setData(response?.data);
      setLoading(false);
    },
    [cruisesInfo.shipName, sessionId]
  );

  useEffect(() => {
    getSpaTreatmentsList({ date, sort });
  }, [date, sort, getSpaTreatmentsList]);

  return { getSpaTreatmentsList, data, loading, error };
};
