import { useEffect } from "react";
import ItineraryOverview from "../../components/ItineraryOverview";
import RecommendationSlider from "../../components/RecommendationSlider";
import ContentDividerWithIcon from "../../components/ContentDividerWithIcon";
import PlannerContainer from "../../components/PlannerContainer";
import CartSumInfo from "../../components/CartSumInfo";
import { imagePath } from "../../utils/imageUtils";
import If from "../../components/molecules/IF";
import OpenRefundsList from "../../components/molecules/OpenRefundsList";
import browserHistory from "../../utils/history";
import NoActiveCruiseNotice from "../../components/molecules/NoActiveCruiseNotice";
import { setIsItineraryShown } from "../../actions/locationActions";
import BackLink from "../../components/molecules/BackLink";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import { isGoLiveForMusementPerShip } from "config/configUtils";
import { getCruisesInfo } from "utils/cruiseUtils";

const Itinerary = () => {
  const cruises = useAppSelector((state) => state.cruises.cruiseDetails);
  const isAgency = useAppSelector((state) => state.agency.isAuthenticated);
  const dispatch = useAppDispatch();

  const cruisesInfo = getCruisesInfo(cruises);

  const shouldShowMXPRecommendations = !isGoLiveForMusementPerShip({
    shipName: cruisesInfo.shipName,
    cruiseStart: cruisesInfo.cruiseStart,
  });

  useEffect(() => {
    dispatch(setIsItineraryShown(true));
    return () => {
      dispatch(setIsItineraryShown(false));
    };
  }, [dispatch]);

  return (
    <div>
      <PlannerContainer sticky={true} />

      <div className="container mx-auto">
        <div className="mt-4">
          <BackLink to="/dashboard" text="Back to Cruise Control Dashboard" />
        </div>

        <h1>Cruise control</h1>

        {cruises && cruises.length === 0 && <NoActiveCruiseNotice />}

        {cruises && cruises.length > 0 && (
          <>
            <ItineraryOverview />

            {/*cost sum and continue button*/}
            <CartSumInfo
              onClick={() => {
                browserHistory.push("/checkout");
              }}
            />
          </>
        )}

        <If test={isAgency}>
          <OpenRefundsList />
        </If>
      </div>

      {shouldShowMXPRecommendations && (
        <RecommendationSlider
          title="Recommended shore excursions"
          path="prebooking/cruises/recommendedExcursions"
        />
      )}

      {/*WHY book with us*/}
      <div className="container mx-auto my-8">
        <ContentDividerWithIcon
          iconAltText="questionmark icon"
          iconSrc={imagePath("questionmark.svg")}
          title="Why book with us?"
        />

        <div className="text-grey-darker my-4 px-2 sm:px-4">
          <div className="text-blue-dark uppercase mt-2">
            MAKING EVERY SECOND COUNT
          </div>
          <div className="text-sm leading-normal">
            With us, you can ditch the guidebooks and let us lead the way, so
            your precious time in port isn’t wasted finding your way around.
          </div>

          <div className="text-blue-dark uppercase mt-2">
            THE SEAL OF APPROVAL
          </div>
          <div className="text-sm leading-normal">
            Our teams experience and review all our tours so you can be assured
            of top quality. A trip you won’t forget for all the right reasons.
          </div>

          <div className="text-blue-dark uppercase mt-2">SAFE AND SOUND</div>
          <div className="text-sm leading-normal">
            We've teamed up with the best tour suppliers in each area. Approved
            by us and ultra-reliable.
          </div>

          <div className="text-blue-dark uppercase mt-2">
            LAST MINUTE CHANGES TAKEN CARE OF
          </div>
          <div className="text-sm leading-normal">
            Should any detail of your cruise change last minute or one of our
            tours gets held up, don’t worry, our team onboard are there to
            organise everything for you.
          </div>

          <div className="text-blue-dark uppercase mt-2">
            SPEAKING THE LINGO
          </div>
          <div className="text-sm leading-normal">
            All our trips have English-speaking guides who really know their
            stuff.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Itinerary;
