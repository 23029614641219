import { getIsRiverUrl } from "config/configUtils";
import { AppConfigAction } from "../actions/appConfigActions";
import { IAppConfig } from "interfaces/ReduxState/IAppConfig";

const INITIAL_STATE: IAppConfig = {
  isRiverUrl: getIsRiverUrl(),
  featureFlags: [],
};

const appConfigReducers = (state = INITIAL_STATE, action: AppConfigAction) => {
  switch (action.type) {
    case "SET_IS_RIVER_URL":
      return { ...state, isRiverUrl: action.isRiverUrl };

    case "CLEAR_IS_RIVER_URL":
      return { ...state, isRiverUrl: INITIAL_STATE.isRiverUrl };

    case "SET_FEATURE_FLAGS":
      return { ...state, featureFlags: action.featureFlags };

    case "CLEAR_FEATURE_FLAGS":
      return { ...state, featureFlags: INITIAL_STATE.featureFlags };

    case "CLEAR_APP_CONFIG":
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default appConfigReducers;
