import { store } from "WithProviders";
import { getCurrentCruisesFromStore } from "actions/cruisesActions";
import axios from "axios";
import { getPrebookUrl } from "config/configUtils";
import { addDays, format, parse, subDays } from "date-fns";
import { IItineraryExcursions } from "interfaces/Musement/IItineraryExcursion";
import { ICruiseDetails } from "interfaces/ReduxState/ICruise";
import { getCruisesInfo } from "utils/cruiseUtils";

const fetchExcursionsForCruise = async (
  cruise: ICruiseDetails,
  fromDate: string,
  toDate: string
): Promise<IItineraryExcursions> => {
  const URL = `${getPrebookUrl()}/api/activities/search?itinerary_in=${
    cruise.externalId
  }&available_from=${fromDate}&available_to=${toDate}`;

  try {
    const response = await axios.get(URL, {
      headers: { SessionId: store.getState().authentication.sessionId },
    });
    return response.data;
  } catch (error) {
    console.error(`Error executing API call "${URL}": ${error}`);
    return {};
  }
};

/**
 * Processes cruises to fetch excursion data.
 * @returns A promise that resolves to an object mapping dates to excursions if successful, otherwise logs and throws an error.
 */
const getExcursionsByCruiseId = async (): Promise<IItineraryExcursions> => {
  const { cruiseDetails: cruises } = getCurrentCruisesFromStore();

  if (cruises.length === 0) {
    console.error("No cruises found.");
    return {};
  }

  const cruisesInfo = getCruisesInfo(cruises);

  const allExcursions: IItineraryExcursions = {};

  for (let i = 0; i < cruises.length; i++) {
    const cruise = cruises[i];

    if (!cruise.externalId) {
      console.error(
        `No cruise external id found '${cruise.externalId}', cruiseStart: ${cruisesInfo.cruiseStart}, cruiseEnd: ${cruisesInfo.cruiseEnd}`
      );
      return {};
    }
    // skip the first date of first first cruise and the last day of the last cruise
    // also if there are more than one cruise - pick the first they of next cruise
    // instead of the last day of previous cruise
    let fromDate = parse(cruise.fromDate, "dd/MM/yyyy", new Date());
    let toDate = parse(cruise.toDate, "dd/MM/yyyy", new Date());
    toDate = subDays(toDate, 1);
    // Adjust 'fromDate' for all cruises except the first/only one
    if (i === 0 ?? cruises.length === 0) {
      fromDate = addDays(fromDate, 1);
    }

    // Format dates for the API request
    const formattedFromDate = format(fromDate, "yyyy-MM-dd");
    const formattedToDate = format(toDate, "yyyy-MM-dd");

    const excursionsByDate = await fetchExcursionsForCruise(
      cruise,
      formattedFromDate,
      formattedToDate
    );

    for (const [date, excursions] of Object.entries(excursionsByDate)) {
      allExcursions[date] = allExcursions[date]
        ? [...allExcursions[date], ...excursions]
        : excursions;
    }
  }

  return allExcursions;
};

export default getExcursionsByCruiseId;
