import { store } from "WithProviders";
import { getFromHub } from "./networkActions";
import { Action } from "redux";
import { AppThunk } from "configureStore";
import getExcursionsByCruiseId from "modules/musement/hooks/getExcursionByCruiseId";
import { IItineraries } from "interfaces/ReduxState/IItinerary";
import { IItineraryExcursions } from "interfaces/Musement/IItineraryExcursion";

export interface ItineraryAction extends Action {
  itinerary?: IItineraries | undefined;
  itineraryId?: string;
  open?: any;
  itineraryDate?: any;
  payload?: any;
}

export const setItinerary = (itineraryEntries: any) => {
  const itinerary = itineraryEntries.map((itineraryEntry: any) => {
    itineraryEntry.expanded = false;
    itineraryEntry.scrollTo = false;
    return itineraryEntry;
  });

  store.dispatch({ type: "SET_ITINERARY", itinerary });
};

export const updateItinerary = async () => {
  await getFromHub("prebooking/cruises/itinerary", setItinerary);
};

export const updateItineraryExcursions =
  (): AppThunk<any> => async (dispatch) => {
    const itineraries: IItineraries | undefined = store.getState().itinerary;

    const fetchAllExcursions = async () => {
      const allExcursions: IItineraryExcursions =
        await getExcursionsByCruiseId();

      const updatedItineraries =
        Object.keys(allExcursions).length > 0
          ? itineraries?.map((itinerary) => {
              return {
                ...itinerary,
                excursions: allExcursions[itinerary.date] || [],
              };
            })
          : itineraries?.map((itinerary) => ({ ...itinerary, excursions: [] }));

      // Update itinerary with additional "excursions" key
      dispatch({ type: "SET_ITINERARY", itinerary: updatedItineraries });
    };

    await fetchAllExcursions();
  };

export const expandAll = () => {
  store.dispatch<ItineraryAction>({ type: "ITINERARY_TOGGLE_ALL", open: true });
};

export const collapseAll = () => {
  store.dispatch<ItineraryAction>({
    type: "ITINERARY_TOGGLE_ALL",
    open: false,
  });
};

export const toggleExpand = (itineraryId: any, open = null) => {
  store.dispatch<ItineraryAction>({
    type: "ITINERARY_TOGGLE_ONE",
    itineraryId,
    open,
  });
};

export const setScrollToItineraryEntry = (itineraryKey: any) => {
  if (itineraryKey && itineraryKey.length > 10) {
    store.dispatch<ItineraryAction>({
      type: "ITINERARY_SET_SCROLL_TO",
      itineraryId: itineraryKey,
    });
  } else {
    store.dispatch<ItineraryAction>({
      type: "ITINERARY_SET_SCROLL_TO",
      itineraryDate: itineraryKey,
    });
  }
};

export const setItineraryExcursions =
  (payload: { itineraryId: string; excursions: any[] }): AppThunk<any> =>
  async (dispatch) => {
    dispatch({ type: "SET_ITINERARY_EXCURSIONS", payload });
  };
