import { FC, HTMLProps, useEffect } from "react";
import { imagePath } from "../../utils/imageUtils";
import { Link } from "react-router-dom";
import useGet from "../../hooks/useGet";
import SvgIcon from "../../components/atoms/SvgIcon";
import { classNames } from "../../utils/miscUtils";
import { setIsDashboardShown } from "../../actions/locationActions";
import {
  getBoardingTileText,
  getCruisePlannerTileText,
  getLuggageTileText,
  getPassportInformationTileText,
  getTravelTileText,
} from "../../utils/dashboardUtils";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const isRiverUrl = useAppSelector((state) => state.appConfig.isRiverUrl);

  // TODO check if this dashboard shown is used
  useEffect(() => {
    dispatch(setIsDashboardShown(true));

    return () => {
      dispatch(setIsDashboardShown(false));
    };
  }, [dispatch]);

  const {
    apiStatus,
    travelInfoOverAllStatus,
    covidSurveyOverallStatus,
    isThirdPartyBooking,
    showCruisePlannerTile,
    showPreTravelTile,
    preTravelSubmissionOpen,
    preTravelSubmissionElapsed,
    showInHousePassportInfoTile,
    show3rdPartyPassportInfoTile,
    covidSurveySubmissionElapsed,
    showBoardingPassTile,
    boardingPassAccessOpen,
    showLuggageTagsTile,
    covidPositive,
  } = useGet("precruise/status", {});

  if (!travelInfoOverAllStatus) {
    return null;
  }

  const isRiver = isRiverUrl;

  return (
    <div>
      <div className="container mx-auto">
        <h1>Cruise Control dashboard</h1>
      </div>

      <div className="bg-blue-lightest-25 py-8 my-8 shadow">
        <div className="container mx-auto">
          <div className="grid gap-x-4 gap-y-6 sm:grid-cols-2 xl:grid-cols-3">
            <DashboardTile
              linkTo="/precruise/passengers"
              icon="family"
              title="Travel information"
              description={getTravelTileText(
                travelInfoOverAllStatus,
                preTravelSubmissionOpen,
                preTravelSubmissionElapsed
              )}
              status={travelInfoOverAllStatus}
              disabled={!showPreTravelTile}
            />

            <DashboardTile
              linkTo="/itinerary"
              icon="anchor"
              title="Cruise planner"
              description={
                isRiver
                  ? "Coming Soon"
                  : getCruisePlannerTileText(showCruisePlannerTile)
              }
              disabled={isRiver ? true : !showCruisePlannerTile}
            />

            {!isRiver && isThirdPartyBooking && (
              <DashboardTile
                linkTo="/apis"
                icon="visa"
                title="Passport information"
                status={apiStatus}
                description={getPassportInformationTileText(
                  isThirdPartyBooking,
                  apiStatus,
                  showInHousePassportInfoTile
                )}
                disabled={!show3rdPartyPassportInfoTile}
              />
            )}

            {!isRiver && !isThirdPartyBooking && (
              <DashboardTile
                externalLinkText={getPassportInformationTileText(
                  isThirdPartyBooking,
                  apiStatus,
                  showInHousePassportInfoTile
                )}
                linkTo="https://www.tui.co.uk/destinations/your-account/managemybooking/login"
                icon="visa"
                title="Passport information"
                status={apiStatus}
                disabled={!showInHousePassportInfoTile}
              />
            )}

            {!isRiver && (
              <>
                <DashboardTile
                  linkTo="/boarding"
                  icon="passport"
                  title="Boarding passes"
                  description={getBoardingTileText(
                    travelInfoOverAllStatus,
                    covidSurveyOverallStatus,
                    covidPositive,
                    boardingPassAccessOpen,
                    covidSurveySubmissionElapsed
                  )}
                  disabled={!showBoardingPassTile}
                />

                <DashboardTile
                  linkTo="/luggage"
                  icon="pricetag"
                  title="Luggage tags"
                  description={getLuggageTileText(showLuggageTagsTile)}
                  disabled={!showLuggageTagsTile}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

type DashboardTileProps = {
  disabled: boolean;
  externalLinkText?: string;
  status?: string;
  linkTo: string;
  icon: string;
  title: string;
  description?: string;
};

const DashboardTile: FC<DashboardTileProps> = ({
  description,
  icon,
  linkTo,
  externalLinkText,
  status,
  title,
  disabled,
}) => {
  const Component = disabled
    ? DisabledDiv
    : externalLinkText
    ? ExternalLink
    : Link;
  const titleClass = disabled ? "text-grey-light" : "text-blue-dark";
  const descriptionClass = disabled ? "text-grey-light" : "";
  return (
    <Component
      className="bg-white border text-center p-4 shadow"
      to={linkTo}
      disabled={disabled}
    >
      <div>
        <div className="relative inline-block">
          {status && (
            <div className="absolute mt-2 right-0">
              {status === "COMPLETE" ? (
                <SvgIcon
                  name="tick-circle"
                  className={classNames(
                    "w-6 h-6",
                    disabled ? "text-grey-light" : "text-green"
                  )}
                />
              ) : (
                <SvgIcon
                  name="info-circle"
                  className={classNames(
                    "w-6 h-6",
                    disabled ? "text-grey-light" : "text-red-tui"
                  )}
                />
              )}
            </div>
          )}
          <SvgIcon
            name={icon}
            className={`w-18 h-18 mx-2 inline-block ${
              disabled ? "text-grey-light" : "text-blue-dark"
            }`}
          />
        </div>
      </div>
      <div className={`mt-4 uppercase text-lg font-bold ${titleClass}`}>
        {title}
      </div>

      {description && (
        <div className={`mt-1 min-h-10 ${descriptionClass}`}>{description}</div>
      )}

      {externalLinkText && (
        <div className="mt-1 min-h-10 leading-middle text-blue">
          <span className="underline">{externalLinkText}</span>{" "}
          <img
            className="inline-block align-text-top"
            src={imagePath("external_link_icon.png")}
            alt=""
          />
        </div>
      )}
    </Component>
  );
};

type DisabledDivProps = HTMLProps<HTMLDivElement> & {
  className: string;
};

const DisabledDiv: FC<DisabledDivProps> = (props) => {
  return (
    <div
      {...props}
      className={`${props.className} text-grey-light cursor-not-allowed`}
    ></div>
  );
};

type ExternalLinkProps = HTMLProps<HTMLAnchorElement> & {
  to: string;
};

const ExternalLink: FC<ExternalLinkProps> = (props) => {
  return (
    <a {...props} href={props.to} target="_blank" rel="noopener noreferrer"></a>
  );
};
