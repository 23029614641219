import { FC, PropsWithChildren, useEffect } from "react";
import ImageWithFallback from "./atoms/ImageWithFallback";
import useAppSelector from "../hooks/useAppSelector";
import { getCruisesInfo } from "utils/cruiseUtils";
import { setCruiseImage } from "../actions/cruisesActions";
import { isGoLiveForMusementPerShip } from "config/configUtils";
import { getFromHub, getResourceImageUrl } from "actions/networkActions";

type Props = PropsWithChildren & {};

const CruiseHeaderImage: FC<Props> = ({ children }) => {
  const itinerary = useAppSelector((state) => state.itinerary);

  const imageReferenceIdFromCruises = useAppSelector((state) =>
    state.cruises ? state.cruises.imageReferenceId : null
  );
  const cruiseDetails = useAppSelector(
    (state) => state?.cruises?.cruiseDetails
  );
  const cruisesInfo = getCruisesInfo(cruiseDetails);

  const shouldShowMusementExcursions = isGoLiveForMusementPerShip({
    shipName: cruisesInfo.shipName,
    cruiseStart: cruisesInfo.cruiseStart,
  });

  // If it is Musement there will be excursions in each itinerary
  // If it is not it will be null
  const imageReferenceId =
    itinerary?.find(
      (itineraryElement) =>
        itineraryElement?.excursions && itineraryElement?.excursions?.length > 0
    )?.excursions?.[0]?.cover_image_url ?? null;

  useEffect(() => {
    // Only if it is not Musement and not existing img reference id -> get the image refference from cloud hub
    if (!imageReferenceIdFromCruises && !shouldShowMusementExcursions) {
      getFromHub("prebooking/cruises/firstExcursions", setCruiseImage);
    }
  }, [imageReferenceIdFromCruises, shouldShowMusementExcursions]);

  // If Musement Feature is On and there is no image reference from musement itineraries
  if (shouldShowMusementExcursions && !imageReferenceId) {
    return null;
  }

  // If Musement Feature is OFF and there is no image reference from MXP cruise details
  if (!shouldShowMusementExcursions && !imageReferenceIdFromCruises) {
    return null;
  }

  return (
    <div className="container-2-1 md:full-height-container xl:container-2-1">
      <ImageWithFallback
        className="container-2-1-img md:full-height-container-img xl:container-2-1-img cover-fit"
        alt="cruise header"
        src={
          shouldShowMusementExcursions
            ? imageReferenceId
            : getResourceImageUrl(imageReferenceIdFromCruises, {
                category: "cruiseExcursion",
              })
        }
      />
      {children}
    </div>
  );
};

export default CruiseHeaderImage;
