import SvgIcon from "components/atoms/SvgIcon";
import { FC, PropsWithChildren } from "react";

type ExcursionFilterProps = PropsWithChildren & {
  filterBy: string;
  closeFilter: () => void;
  clearFilter: () => void;
};

const FilterBody: FC<ExcursionFilterProps> = ({
  filterBy,
  closeFilter,
  clearFilter,
  children,
}) => {
  return (
    <div>
      {/* filter header */}
      <div className="container mx-auto">
        <div className="py-4 border-b">
          Filter by <span className="font-bold">{filterBy}</span>
          <div
            className="float-right text-blue-light cursor-pointer"
            onClick={closeFilter}
          >
            <SvgIcon name="x" className="w-4 h-4 inline" />
          </div>
        </div>
      </div>

      {/* filter content */}
      <div className="container mx-auto">
        <div className="px-4">{children}</div>
      </div>

      {/* filter footer */}
      <div className="bg-blue-lightest">
        <div className="container mx-auto py-4 text-right">
          <div className="uppercase cursor-pointer" onClick={clearFilter}>
            clear
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBody;
