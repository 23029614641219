import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { getOswUrl } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";

type Props = {
  amount: number;
  currency: string;
  tenantReference: string;
  emailAddress: string;
  mobileNumber: string;
};

export const usePostInitPaymentAgencyOSW = () => {
  const userSessionId = useAppSelector(
    (state) => state.authentication.sessionId
  );
  const agencyAuthInfo = useAppSelector((state) => state.agency);
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation<any, Error, Props>({
    mutationKey: ["usePostInitPaymentAgencyOSW"],
    mutationFn: async ({
      amount,
      currency,
      tenantReference,
      emailAddress,
      mobileNumber,
    }: Props) => {
      const URL = `${getOswUrl()}/api/payments/agency/confirm`;

      const response = await axios.post(
        URL,
        {
          amount,
          currency,
          tenantReference,
          emailAddress,
          mobileNumber,
          sessionId: agencyAuthInfo.sessionId,
          sessionType: agencyAuthInfo.sessionType,
          sessionName: agencyAuthInfo.sessionName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            SessionId: userSessionId,
          },
        }
      );

      return response?.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["usePostInitPaymentAgencyOSW"],
      });
    },
    onError: (error) => {
      const msg = `Error executing API call "${URL}": ${error}`;
      console.error(msg);
      return msg;
    },
    gcTime: 0,
  });

  return {
    postInitPaymentAgencyOSW: mutateAsync,
  };
};
